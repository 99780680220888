<template>
  <div>
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click.stop="handleOpenModal"
        >
          <font-awesome-icon icon="retweet" />
        </div>
      </template>
      <span>Génerer facture PROFORMA Masters</span>
    </v-tooltip>

    <v-dialog
      v-model="dialog"
      max-width="1000px"
      scrollable
      :persistent="true"
      :hide-overlay="true"
      content-class="custom-vuetify-dialog-rapport"
    >
      <v-card class="custom-dialog">
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"
            >Génerer des factures PROFORMA Masters</v-label
          >
          <v-btn
            class="btn-close-header"
            icon
            @click="resetModal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card mt-2">
          <v-form>
            <v-label class="title-tabel-global"
              >Voulez vous générer des factures proforma Pour les Masters
              ?</v-label
            >
            <v-row class="row-top mb-2">
              <v-col cols xs="9" sm="9" md="9" class="date-generate-fac">
                <v-menu :close-on-content-click="true" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_depot"
                      label="Date dépôt"
                      outlined
                      readonly
                      hide-details
                      clearable
                      v-on="on"
                      v-bind="attrs"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner un date"
                      prepend-inner-icon="mdi-calendar"
                      @click:clear="handleDateTimePicker(null, 'date_depot')"
                      color="#704ad1"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="date_depot"
                    @input="handleListDossier()"
                    @click:clear="handleDateTimePicker(null, 'date_depot')"
                    no-title
                    locale="fr"
                    color="#704ad1"
                    item-color="#704ad1"
                    width="560"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <!-- MASTER FILIALE -->
            <v-label class="mt-3 mb-3 title-tabel-global"
              >Master filiale</v-label
            >
            <v-row>
              <v-col>
                <v-checkbox
                  label="Tous"
                  color="#704ad1"
                  hide-details
                  v-model="checkAll"
                  @change="changeAllCheckMasters"
                ></v-checkbox>
              </v-col>
            </v-row>
            <div
              class="chargement chargement-loading-icon mt-1 ml-3 "
              v-if="getLoadingSettingFilialeTh"
            >
              <div class="spinner-border sp-co-color" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div
              v-if="getSettingFilialesTh.length"
              class="custom-class-fields-av scroll-specifics-params"
            >
              <div
                v-for="(option, index) in getSettingFilialesTh"
                :key="index"
                class="mr-3"
              >
                <v-checkbox
                  color="#704ad1"
                  hide-details
                  v-model="masters_id"
                  @change="handleListDossier()"
                  :value="option.id"
                  :label="option.name"
                >
                </v-checkbox>
              </div>
            </div>

            <!--  LISTE DE DOSSIER -->
            <v-label class="mt-4 mb-3 title-tabel-global"
              >Liste des dossiers</v-label
            >
            <v-row
              class="groupe-check-search"
              v-if="getListProjectForSpecificDate.length && !loaderProject"
            >
              <v-col>
                <v-checkbox
                  label="Tous"
                  color="#704ad1"
                  hide-details
                  v-model="checkAllDossier"
                  @change="changeAllCheckDossiers"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-text-field
                  label="Rechercher"
                  placeholder="Rechercher"
                  :persistent-placeholder="true"
                  outlined
                  v-model="searchValue"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
            </v-row>
            <div
              class="chargement chargement-loading-icon error-msg-not-dossier"
              v-if="loaderProject"
            >
              <div class="spinner-border sp-co-color" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div
              v-if="getListProjectForSpecificDate.length == 0 && !loaderProject"
              class="error-msg-not-dossier mb-2"
            >
              Aucun dossier
            </div>
            <div
              v-if="getListProjectForSpecificDate.length && !loaderProject"
              class="custom-class-fields-av scroll-specifics-params mt-4 mb-3"
            >
              <div
                v-for="(option, index) in computedListDossier"
                :key="index"
                class="mr-3"
              >
                <v-checkbox
                  color="#704ad1"
                  hide-details
                  v-model="projects_ids"
                  @change="changeOptionDossier"
                  :value="option.id"
                  :label="option.numero_dossier"
                >
                </v-checkbox>
              </div>
            </div>
            <v-divider
              class="line-hr-header"
              v-if="getListProjectForSpecificDate.length && !loaderProject"
            ></v-divider>
          </v-form>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)" class="mb-0">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="handleGenerate"
            :loading="loading"
            :class="{ loader: loading }"
          >
            Générer
          </v-btn>
          <v-btn text @click="resetModal">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MODAL RAPPORT CREATION PROFORMA MASTER -->
    <v-dialog
      v-model="dialogError"
      max-width="1800px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-rapport"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"
            >Rapport Création Facture(s) Proforma Masters</v-label
          >
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="handleModalError('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-row class="mt-4">
            <v-col cols="12" v-if="listFactureSuccess.length">
              <div class="titleFiche">
                Factures ajouté avec succès ({{ listFactureSuccess.length }}):
              </div>
              <v-simple-table class="table-master mt-1">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center dark-class">Société</th>
                      <th class="text-center dark-class">Client</th>
                      <th class="text-center dark-class">Numéro Facure</th>
                      <th class="text-center dark-class">Montant</th>
                      <th class="text-center dark-class">
                        Nombre des produits
                      </th>
                      <th class="text-center dark-class">Coefficient</th>
                      <th class="text-center dark-class">Objet</th>
                      <th class="text-center dark-class">Template</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in listFactureSuccess"
                      :key="index"
                    >
                      <td class="text-center">{{ item.vendeur }}</td>
                      <td class="text-center">{{ item.client }}</td>
                      <td class="text-center">{{ item.num }}</td>
                      <td class="text-center">{{ item.ttc }} €</td>
                      <td class="text-center">{{ item.number_of_products }}</td>
                      <td class="text-center">{{ item.coef }}</td>
                      <td class="text-center">{{ item.objet }}</td>
                      <td class="text-center">{{ item.template }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" v-if="listFacturesExiste.length">
              <div class="titleFiche">
                Facture(s) modifiée(s) ({{ listFacturesExiste.length }}) :
              </div>
              <v-simple-table class="table-master mt-1">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center dark-class">Société</th>
                      <th class="text-center dark-class">Client</th>
                      <th class="text-center dark-class">Numéro Facure</th>
                      <th class="text-center dark-class">Montant</th>
                      <th class="text-center dark-class">
                        Nombre des produits
                      </th>
                      <th class="text-center dark-class">Coefficient</th>
                      <th class="text-center dark-class">Objet</th>
                      <th class="text-center dark-class">Template</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in listFacturesExiste"
                      :key="index"
                    >
                      <td class="text-center">{{ item.vendeur }}</td>
                      <td class="text-center">{{ item.client }}</td>
                      <td class="text-center">{{ item.num }}</td>
                      <td class="text-center">{{ item.montant_ttc }} €</td>
                      <td class="text-center">{{ item.number_of_products }}</td>
                      <td class="text-center">{{ item.coef }}</td>
                      <td class="text-center">{{ item.objet }}</td>
                      <td class="text-center">{{ item.template }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" v-if="listProformaToFacture.length">
              <div class="titleFiche">
                Proforma transfère à facture ({{
                  listProformaToFacture.length
                }}):
              </div>
              <v-simple-table class="table-master mt-1">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center dark-class">Société</th>
                      <th class="text-center dark-class">Client</th>
                      <th class="text-center dark-class">Numéro Facure</th>
                      <th class="text-center dark-class">Montant</th>
                      <th class="text-center dark-class">Numéro Proforma</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in listProformaToFacture"
                      :key="index"
                    >
                      <td class="text-center">{{ item.vendeur }}</td>
                      <td class="text-center">{{ item.client }}</td>
                      <td class="text-center">{{ item.num }}</td>
                      <td class="text-center">{{ item.montant_ttc }} €</td>
                      <td class="text-center">{{ item.num_proforma }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="6" v-if="locked_factures.length">
              <div class="titleFiche">
                Proforma transfère à facture ({{ locked_factures.length }}):
              </div>
              <v-simple-table class="table-master mt-1">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center dark-class">Numéro Facure</th>
                      <th class="text-center dark-class">Montant</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in locked_factures" :key="index">
                      <td class="text-center">{{ item.num }}</td>
                      <td class="text-center">{{ item.ttc }} €</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="6" v-if="listSocieteErrorRib.length">
              <div class="titleFiche">
                Liste des sociétés qui n'ont pas de RIB ({{
                  listSocieteErrorRib.length
                }}):
              </div>
              <v-list>
                <v-list-item>
                  <v-list-item-content class="content-list-groupe">
                    <v-list-item-title
                      class="title-list-sci-mensuel"
                      v-for="(item, index) in listSocieteErrorRib"
                      v-bind:key="index"
                      >{{ item }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col v-if="listSocieteError.length" cols="6">
              <div class="titleFiche">
                Liste des sociétés qui ont des erreurs de séquence ({{
                  listSocieteError.length
                }}):
              </div>
              <v-list>
                <v-list-item>
                  <v-list-item-content class="content-list-groupe">
                    <v-list-item-title
                      class="title-list-sci-mensuel"
                      v-for="(item, index) in listSocieteError"
                      v-bind:key="index"
                      >{{ item }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col v-if="noChange" cols="6">
              <div class="titleFiche">
                Aucun changement
              </div>
              <v-list>
                <v-list-item>
                  <v-list-item-content class="content-list-groupe">
                    <v-list-item-title class="title-list-sci-mensuel">{{
                      noChange
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn @click.prevent="handleModalError('hide')" color="#704ad1" text>
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'GenerateFactureMaster',
  data() {
    return {
      loading: false,
      error: null,
      locked_factures: [],
      listProformaToFacture: [],
      listFacturesExiste: [],
      listFactureSuccess: [],
      listSocieteError: [],
      listSocieteErrorRib: [],
      noChange: null,
      date_depot: null,
      masters_id: [],
      checkAll: true,
      projects_ids: [],
      checkAllDossier: true,
      loaderProject: false,
      searchValue: null,
      dialog: false,
      dialogError: false
    };
  },
  props: {
    functionGenerate: { required: true }
  },
  computed: {
    ...mapGetters([
      'getSettingFilialesTh',
      'getLoadingSettingFilialeTh',
      'getListProjectForSpecificDate'
    ]),
    computedListDossier() {
      let table = this.getListProjectForSpecificDate;
      if (this.searchValue != null) {
        table = table.filter(item =>
          item.numero_dossier
            .toLowerCase()
            .includes(this.searchValue.toLowerCase())
        );
      }
      return table;
    }
  },
  methods: {
    ...mapActions(['fetchProjectForSpecifcDate', 'resetProjectForSpecifcDate']),
    handleDateTimePicker(value, name) {
      this[name] = value;
      this.handleListDossier();
    },
    async handleListDossier() {
      this.error = null;
      this.checkAll =
        this.masters_id.length == this.getSettingFilialesTh.length
          ? true
          : false;
      this.projects_id = [];
      if (this.date_depot != null && this.masters_id.length > 0) {
        this.loaderProject = true;
        await this.fetchProjectForSpecifcDate({
          date_depot: moment(this.date_depot).format('DD-MM-YYYY'),
          masters: this.masters_id
        });
        this.loaderProject = false;
      } else if (this.date_depot == null && this.masters_id.length == 0) {
        this.error =
          'Séléctionner un date de dépot et au moins une master filiale';
      } else if (this.masters_id.length == 0) {
        this.error = 'Séléctionner au moins une master filiale';
      } else if (this.date_depot == null) {
        this.error = 'Séléctionner un date de dépot ';
      }
    },
    resetModal() {
      this.loading = false;
      this.dialog = false;
      this.error = null;
      this.masters_id = [];
      this.projects_ids = [];
      this.date_depot = null;
      this.checkAll = true;
      this.masters_id = this.checkAll
        ? this.getSettingFilialesTh.map(i => i.id).slice()
        : [];
      this.checkAllDossier = true;
    },
    async handleGenerate() {
      this.error = null;
      if (this.date_depot == null && this.masters_id.length == 0) {
        this.error =
          'Séléctionner un date de dépot et au moins une master filiale';
      } else if (this.masters_id.length == 0) {
        this.error = 'Séléctionner au moins une master filiale';
      } else if (this.date_depot == null) {
        this.error = 'Séléctionner un date de dépot ';
      } else {
        this.loading = true;
        const response = await this.functionGenerate({
          date_depot: this.date_depot,
          masters_ids: this.masters_id,
          projects_ids: this.projects_ids
        });
        if (response.success) {
          this.dialog = false;
          this.error = null;
          this.loading = false;
          this.locked_factures = response.response.locked_factures;
          this.listProformaToFacture = response.response.proforma_to_facture;
          this.listFacturesExiste = response.response.updated;
          this.listFactureSuccess = response.response.created;
          this.listSocieteError = response.response.no_seq;
          this.listSocieteErrorRib = response.response.no_rib;
          this.noChange = response.response.no_changes;
          this.handleModalError('show');
        } else {
          this.error = response.errors;
          this.loading = false;
        }
      }
    },
    handleModalError(action) {
      if (action == 'show') {
        this.dialogError = true;
      } else {
        this.dialogError = false;
        this.locked_factures = [];
        this.listProformaToFacture = [];
        this.listFacturesExiste = [];
        this.listFactureSuccess = [];
        this.listSocieteError = [];
        this.listSocieteErrorRib = [];
        this.noChange = null;
        this.date_depot = null;
        this.masters_id = [];
        this.checkAll = true;
        this.$emit('RefreshData');
      }
    },
    handleOpenModal() {
      this.dialog = true;
      this.projects_ids = [];
      this.resetProjectForSpecifcDate();
    },
    changeAllCheckMasters() {
      this.error = null;
      this.masters_id = this.checkAll
        ? this.getSettingFilialesTh.map(i => i.id).slice()
        : [];
      this.handleListDossier();
    },
    changeAllCheckDossiers() {
      this.projects_ids = this.checkAllDossier
        ? this.computedListDossier.map(i => i.id).slice()
        : [];
    },
    changeOptionDossier() {
      this.checkAllDossier =
        this.projects_ids.length == this.computedListDossier.length
          ? true
          : false;
    }
  },
  watch: {
    getSettingFilialesTh() {
      this.changeAllCheckMasters();
    },
    computedListDossier() {
      this.changeAllCheckDossiers();
    }
  }
};
</script>

<style lang="scss" scoped>
.title-list-sci-mensuel {
  border: 1px solid #e0e0e0;
  margin-bottom: 0px !important;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: 2;
}
.content-list-groupe {
  padding: 0px 60px;
}
.titleFiche {
  color: #704ad1;
  font-weight: 600 !important;
  font-family: 'Montserrat', sans-serif;
}
.scroll-specifics-params {
  height: 150px;
  overflow-y: auto;
  align-content: start;
  align-items: flex-start;
}
.scroll-specifics-params::-webkit-scrollbar-track {
  background: #f0eef8;
  border-radius: 30px;
  width: 15px;
  border-right: 10px solid white;
  border-left: 10px solid white;
  border-bottom: 10px solid white;
  border-top: 10px solid white;
}
.scroll-specifics-params::-webkit-scrollbar {
  width: 30px;
  height: 30px;
}
.scroll-specifics-params::-webkit-scrollbar-thumb {
  background: #704ad1;
  border-radius: 25px;
  border: 12px solid transparent;
  background-clip: padding-box;
}
.custom-class-fields-av {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  overflow-x: auto;
  text-align-last: left;
  max-height: auto;
}

.groupe-check-search {
  display: flex;
  align-items: baseline;
}
.error-msg-not-dossier {
  text-align: center;
  border: 1px solid;
  width: 71%;
  padding: 6px;
  margin: auto;
  border-color: transparent;
  color: #704ad1;
  background-color: #ffff;
}

.table-master {
  table,
  td,
  tr,
  th {
    border: 1px solid #e0e0e0;
  }
}
</style>
<style lang="scss">
.date-generate-fac {
  padding: 4px 4px 4px 165px !important;
  .v-icon.v-icon {
    margin-top: -3px;
    font-size: 18px;
  }
}
</style>
